var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"loading":_vm.loading}},[_c('v-card-title',[_c('router-link',{staticClass:"font-weight-medium text-decoration-none me-2",attrs:{"to":{ name: 'Setting-Cashback'}}},[_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiArrowLeftBold))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('back')))])])],1),_vm._v(" "+_vm._s(_vm.cashbackResult.cashback_name)+" "),_c('StatusBlock',{staticClass:"ms-2",attrs:{"status":String(_vm.cashbackResult.cashback_status_id)}}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isAddCashBackCustomerGroup = true}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_vm._v(" "+_vm._s(_vm.$t('add_customer_groups'))+" ")],1)],1),_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(_vm._s(_vm.$t('code'))+" :")]),_vm._v(" "+_vm._s(_vm.cashbackResult.cashback_id)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t('netReceipt'))+" : ")]),_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.cashbackResult.cashback_order))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t('receiveAmount'))+" : ")]),_vm._v(" "+_vm._s(_vm._f("formatPrice")(_vm.cashbackResult.cashback_amount))+" ")]),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3"}},[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.$t('amountExpireDate'))+" : ")]),_vm._v(" "+_vm._s(_vm.cashbackResult.cashback_expire)+" ")])],1),_c('v-data-table',{staticClass:"mt-3",attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"dense":"","options":_vm.options,"footer-props":_vm.footer,"loading":_vm.loading,"hide-default-footer":"","disable-sort":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":item.customer_group_id==="-"},on:{"click":function($event){_vm.removeId = item.cashback_customer_group_id;_vm.isRemove = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")])]}}],null,true)})],1),_c('AddCashbackCutomerGroup',{attrs:{"customer-group-list":_vm.customerGroupList},on:{"onAdd":_vm.getCashbackById},model:{value:(_vm.isAddCashBackCustomerGroup),callback:function ($$v) {_vm.isAddCashBackCustomerGroup=$$v},expression:"isAddCashBackCustomerGroup"}}),_c('CashbackDetailRemove',{attrs:{"remove-id":_vm.removeId},on:{"onRemove":_vm.getCashbackById},model:{value:(_vm.isRemove),callback:function ($$v) {_vm.isRemove=$$v},expression:"isRemove"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }