import { api } from '../main'

const cashbackById = async param => {
  const response = await api
    .get({
      path: 'cashback',
      param,
    })
    .then(res => res.data || {})
    .catch(err => {
      console.log('error: ', err)
      return {}
    })

  return response
}

const cashbackList = async body => {
  const response = await api
    .post({
      path: 'cashback/list',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('error: ', err)
      return {}
    })

  return response
}

const addCashback = async body => {
  const response = await api
    .post({
      path: 'cashback',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('error: ', err)
      return {}
    })

  return response
}

const updateCashback = async body => {
  const response = await api
    .put({
      path: 'cashback',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('error: ', err)
      return {}
    })

  return response
}

const updateCashbackStatus = async body => {
  const response = await api
    .put({
      path: 'cashback/status',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('error: ', err)
      return {}
    })

  return response
}

const getCashbackCustomerGroupList = async body => {
  const response = await api
    .post({
      path: 'cashback/customergrouplist',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('error: ', err)
      return {}
    })

  return response
}

const addCashbackCustomerGroup = async body => {
  const response = await api
    .post({
      path: 'cashback/customergroup',
      body,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('error: ', err)
      return {}
    })

  return response
}

const removeCashbackCustomerGroup = async param => {
  const response = await api
    .delete({
      path: 'cashback/customergroup',
      param,
    })
    .then(res => res || {})
    .catch(err => {
      console.log('error: ', err)
      return {}
    })

  return response
}


export default {
  cashbackById,
  cashbackList,
  updateCashbackStatus,
  addCashback,
  updateCashback,
  getCashbackCustomerGroupList,
  addCashbackCustomerGroup,
  removeCashbackCustomerGroup,
}
