<template>
  <div>
    <v-card :loading='loading'>
      <v-card-title>
        <router-link
          class='font-weight-medium text-decoration-none me-2'
          :to="{ name: 'Setting-Cashback'}"
        >
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                color='primary'
                v-bind='attrs'
                icon
                v-on='on'
              >
                <v-icon>{{ icons.mdiArrowLeftBold }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('back') }}</span>
          </v-tooltip>
        </router-link>
        {{ cashbackResult.cashback_name }}
        <StatusBlock class='ms-2' :status='String(cashbackResult.cashback_status_id)' />
        <v-spacer></v-spacer>
        <v-btn color='primary' @click='isAddCashBackCustomerGroup = true'>
          <v-icon>{{ icons.mdiPlus }}</v-icon>
          {{ $t('add_customer_groups') }}
        </v-btn>
      </v-card-title>
      <v-row class='px-5'>
        <v-col
          cols='12'
          md='6'
          lg='3'
        >
          <span class='font-weight-semibold'>{{ $t('code') }} :</span>
          {{ cashbackResult.cashback_id }}
        </v-col>
        <v-col
          cols='12'
          md='6'
          lg='3'
        >
          <span class='font-weight-semibold'> {{ $t('netReceipt') }} : </span>
          {{ cashbackResult.cashback_order |formatPrice }}
        </v-col>
        <v-col
          cols='12'
          md='6'
          lg='3'
        >
          <span class='font-weight-semibold'> {{ $t('receiveAmount') }} : </span>
          {{ cashbackResult.cashback_amount |formatPrice }}
        </v-col>
        <v-col
          cols='12'
          md='6'
          lg='3'
        >
          <span class='font-weight-semibold'> {{ $t('amountExpireDate') }} : </span>
          {{ cashbackResult.cashback_expire }}
        </v-col>
      </v-row>
      <v-data-table
        class='mt-3'
        :headers='columns'
        :items='dataTableList'
        dense
        :options.sync='options'
        :footer-props='footer'
        :loading='loading'
        hide-default-footer
        disable-sort
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >

        <template v-slot:[`item.id`]='{ index }'>
          {{ index + 1 }}
        </template>

        <template v-slot:[`item.actions`]='{ item }'>
          <v-tooltip
            color='#212121'
            top
          >
            <template v-slot:activator='{ on, attrs }'>
              <v-btn
                icon
                color='primary'
                v-bind='attrs'
                v-on='on'
                :disabled='item.customer_group_id===`-`'
                @click='removeId = item.cashback_customer_group_id;isRemove = true'
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            {{ $t('delete') }}
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddCashbackCutomerGroup v-model='isAddCashBackCustomerGroup'
                             :customer-group-list='customerGroupList' @onAdd='getCashbackById' />
    <CashbackDetailRemove v-model='isRemove' :remove-id='removeId' @onRemove='getCashbackById' />

  </div>
</template>

<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import checkingSet from '@/api/checkingSet/checkingSet'
import {
  mdiPlus,
  mdiPencilOutline,
  mdiCheck,
  mdiDeleteOutline,
  mdiClose,
  mdiArrowLeftBold,
} from '@mdi/js'
import { i18n } from '@/plugins/i18n'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import RemoveDetailCheckingSet from '@/views/checkSet/RemoveDetailCheckingSet'
import DetailCheckingSetUpdateStatus from '@/views/checkSet/DetailCheckingSetUpdateStatus'
import { formatPrice } from '@/plugins/filters'
import cashback from '@/api/cashback/cashback'
import customerGroup from '@/api/customer/customerGroup'
import AddCashbackCutomerGroup from '@/views/systemSetting/cashBack/AddCashbackCutomerGroup'
import CashbackDetailRemove from '@/views/systemSetting/cashBack/CashbackDetailRemove.vue'

export default {
  props: {},
  components: {
    AddCashbackCutomerGroup,
    CashbackDetailRemove,
    DetailCheckingSetUpdateStatus,
    RemoveDetailCheckingSet,
    StatusBlock,
  },
  filters: {
    formatPrice,
  },
  setup(_, { root, emit }) {
    const cashbackResult = ref({})
    const isAddCashBackCustomerGroup = ref(false)
    const checkingGroupList = ref([])
    const checkingGroupId = ref('')
    const options = ref({})
    const footer = ref(i18n.locale === 'en' ? footerDataTablesEN : footerDataTables)
    const search = ref('')
    const dataCheckingList = ref([])
    const dataTableList = ref([])
    const isEditDetailCheckingSet = ref(false)
    const dataEdit = ref({})
    const columns = ref([
      {
        text: '#',
        align: 'start',
        value: 'id',
        width: 70,
      },
      {
        text: i18n.t('name'),
        value: 'customer_group_name',
        width: 200,
      },
      {
        text: i18n.t('option'),
        value: 'actions',
        sortable: false,
        align: 'end',
      },
    ])
    const loading = ref(false)
    const isRemove = ref(false)
    const isUpdateStatus = ref(false)
    const removeId = ref('')
    const customerGroupList = ref([])

    onMounted(() => {
      loading.value = true
      getCustomerGroup()
      getCashbackById()
    })

    const getCustomerGroup = () => {
      console.log('get customer ')
      customerGroup.customerGroupList({
        searchtext: '',
        lang: i18n.locale,
        active_page: 1,
        per_page: '0',
      }).then(res => {
        customerGroupList.value = res.data
        // customerGroupList.value.unshift({
        //   customer_group_id: '',
        //   customer_group_name: i18n.t('all'),
        // })
        console.log('customerGroupList', customerGroupList.value)
      })
    }


    const getCashbackById = () => {
      cashback.cashbackById(root.$route.params.id).then(res => {
        console.log('res : ', res)
        dataTableList.value = res.customer_group
        cashbackResult.value = res
        loading.value = false
      }).catch(err => {
        loading.value = false
        console.log(err)
      })
    }

    const onChangeCheckingGroup = (value) => {
      checkingGroupId.value = value
    }

    return {
      cashbackResult,
      isAddCashBackCustomerGroup,
      checkingGroupList,
      getCashbackById,
      dataCheckingList,
      columns,
      onChangeCheckingGroup,
      dataTableList,
      options,
      footer,
      dataEdit,
      loading,
      checkingGroupId,
      isEditDetailCheckingSet,
      isRemove,
      removeId,
      isUpdateStatus,
      customerGroupList,
      icons: {
        mdiPlus,
        mdiPencilOutline, mdiCheck, mdiDeleteOutline,
        mdiArrowLeftBold,
        mdiClose,
      },
    }
  },

}
</script>

<style scoped>

</style>
