<template>
  <div>
    <v-dialog v-model='isAddCashbackCustomerGroup' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('add_customer_groups') }}
        </v-card-title>
        <v-divider></v-divider>
        <v-form ref='formAddCashbackCustomerGroup' @submit.prevent='createCashbackCustomerGroup'>
          <v-card-text>
            <v-select
              class='mt-3'
              v-model='customer_group_id'
              :items='customerGroupList'
              :label="$t('group_customers')"
              item-text='customer_group_name'
              hide-details='auto'
              outlined
              dense
              item-value='customer_group_id'

            ></v-select>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color='primary' type='submit' :loading='loading' :disabled='loading'>
              {{ $t('confirm') }}
            </v-btn>
            <v-btn color='secondary' outlined
                   @click='$emit(`update:isAddCashbackCustomerGroup`,false)'>{{ $t('cancel') }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import cashback from '@/api/cashback/cashback'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
import { required } from '@core/utils/validation'

export default {
  model: {
    prop: 'isAddCashbackCustomerGroup',
    event: 'update:isAddCashbackCustomerGroup',
  },
  props: {
    isAddCashbackCustomerGroup: {
      type: Boolean,
      default: false,
    },
    customerGroupList: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  setup(props, { root, emit }) {
    const customer_group_id = ref('')
    const formAddCashbackCustomerGroup = ref(null)
    const loading = ref(false)

    const createCashbackCustomerGroup = () => {
      const isFormValid = formAddCashbackCustomerGroup.value.validate()
      if (!isFormValid) return
      loading.value = true
      cashback.addCashbackCustomerGroup({
        cashback_id: root.$route.params.id,
        customer_group_id: customer_group_id.value,
      }).then((res) => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        emit('update:isAddCashbackCustomerGroup', false)
        loading.value = false
      })
    }

    watch(() => props.isAddCashbackCustomerGroup, value => {
      if (value) {
        clearData()
      }
    })
    const clearData = () => {
      customer_group_id.value = ''
    }

    return {
      customer_group_id,
      formAddCashbackCustomerGroup,
      createCashbackCustomerGroup,
      required,
      loading,
    }
  },

}
</script>

<style scoped>

</style>
